import request from '@/api/request'

export function getFirPageRot() {
  return request({
    url: '/homePage/helloworld',
    method: 'get',
  })
}
//根据菜单id获取内容列表
export function getContents(params) {
  return request({
    url: '/back/getContents?menuId='+params.menuId,
    method: 'get',
    data:params
  })
}
export function getNewsContent(params) {
  return request({
    url: '/back/getNewsContents?menuId='+params.menuId+'&pageNum='+params.pageNum+'&pageSize='+params.pageSize,
    method: 'get',
    data:params
  })
}
export function depart() {
  return request({
    url: '/api/kaoqin/data/depart',
    method: 'get',
  })
}

// 查询产品类型/系列
export function getProductTypes(params) {
  return request({
    url: '/back/getProductTypes?'+'id='+params.id,
    method: 'get',
    data:params
  })
}
// 查询文件类型 
export function getFileTypes() {
  return request({
    url: '/back/getFileTypes',
    method: 'get',
  })
}

// 查询所有文件
export function getFiles(params) {
  return request({
    url: '/back/getFiles?'+'fileName='+params.fileName+'&productType='+params.productType+'&serType='+params.serType+'&fileType='+params.fileType,
    method: 'get',
    data:params
  })
}