<template>
  <div class="divCSs">
    <div class="beijing">SOLUTION</div>
    <div class="logoCss">
      <img src="../img/产品类型.png" alt="" />
    </div>
    <div class="titleCss"><span>解决方案</span></div>
    <div class="minCss">
      <div :class="{'buttonljgd3':1 == current}" @click="change(1)" class="buttonljgd1"></div>
      <div :class="{'buttonljgd4':2 == current}" @click="change(2)" class="buttonljgd"></div>
    </div>
    <div class="boxCss">
      <div style="width: 70%">
        <img
          style="width: 576px;
              height: 501px; border-radius: 15px"
          :src="this.dataBox[0].content.dataList[0].url"
          alt=""
        />
      </div>

      <div style="width: 100%; height: 100%; margin-left: 4%">
        <div class="bigtitle">
          <span>01</span
          ><span style="font-size: 36px">{{ this.dataBox[0].content.dataList[0].title }}</span>
        </div>
        <div
          style="
            margin-top: 36px;
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #727171;
            line-height: 32px;
            overflow: hidden;
            height: 44%;
            min-height: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 6;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          "
        >
          {{ this.dataBox[0].content.dataList[0].content }}
        </div>
        <div style="display: flex">
          
          <div style="display: flex;">
            <div
            @click="qiehuan"
            style="
              display: flex;
              width: 272px;
              height: 192px;
              position: relative;
            "
          >
            <img
              style="width: 100%; height: 100%; border-radius: 15px;cursor: pointer;"
              :src="this.dataBox[1].content.dataList[0].url"
              alt=""
            />
            <p class="textInPic">{{ this.dataBox[1].content.dataList[0].title }}</p>
            
          </div>
          <div class="numCss">02</div>
          </div>

          <div style="display: flex;margin-left: 66px;">
            <div
          @click="qiehuan2"
            style="
              display: flex;
              width: 272px;
              height: 192px;
              position: relative;
            "
          >
            <img
              style="width: 100%; height: 100%; border-radius: 15px;cursor: pointer;"
              :src="this.dataBox[2].content.dataList[0].url"
              alt=""
            />
            <span class="textInPic">{{ this.dataBox[2].content.dataList[0].title }}</span>
             
            <!-- <div class="numCss">{{ this.dataBox[2].length }}</div>  -->
          </div>
          <div class="numCss">03</div>
          </div>
          
        </div>
      </div>
    </div>
    <button class="ckxz" @click="go">查看详情</button>
  </div>
</template>

<script>
import { getContents } from "@/api/index.js";
export default {
  data() {
    return {
      current: 1,//选中项
      dataBox: [
        {
          index: "01",
          title: "新能源发电解决方案",
          url: require("../img/新能源.png"),
          content:
            "公司提供分布式光伏电站、集中式光伏电站、风光互补电站、风力发电站等新能源发电项目可研、方案、设计、设备选型采购、项目管理及施工、后期运维全生命周期的新能源发电服务，包括电站项目开发、建设、交易、运维全过程交钥匙整体解决方案；标准化的电站评估交易体系；高效光伏组件；经验丰富的电站设计团队；先进的云计算监控管理平台；量身定制的运营方案，可显著提升电站综合发电效率，降低运维成本。",
        },
        {
          index: "02",
          title: "微电网解决方案",
          url: require("../img/微电网.png"),
          content: "公司依托自身在微电网领域十多年的技术基础及行业应用，针对产业园区、高校、工业企业、偏远地区等地区，提供微电网整体解决方案，包括方案的设计、设备采购、工程服务、运行维护的一站式EPC服务，实现微型、清洁、友好、自治的微电网。",
        },
        {
          index: "03",
          title: "储能解决方案",
          url: require("../img/储能.png"),
          content: "公司结合自身在新能源领域十多年的技术基础及行业应用，为客户提供储能整体解决方案，包括发电侧储能解决方案电网侧储能解决方案以及用户侧储能解决方案。为用户提供项目可研、方案、设计、设备选型采购、项目管理及施工后期运维全生命周期的系统服务。",
        },
      ],
    };
  },
  methods: {
    async getList() {
      let params = {
        menuId: "2038",
        id: "",
      };
      const res = await getContents(params);
      for (let index = 0; index < res.length; index++) {
        // const zj = JSON.parse(res[0].content);
        res[index].content = JSON.parse(res[index].content);
      }
      // const zj = JSON.parse(res[0].content);
      console.log(res, "res38");
      this.dataBox = res;
    },
    async getList2() {
      let params = {
        menuId: "2039",
        id: "",
      };
      const res = await getContents(params);
      for (let index = 0; index < res.length; index++) {
        // const zj = JSON.parse(res[0].content);
        res[index].content = JSON.parse(res[index].content);
      }
      // const zj = JSON.parse(res[0].content);
      console.log(res, "res38");
      this.dataBox = res;
    },
    go(){
      this.$router.push("/solution")
    },
    // getList() {
    //     // this.loading = true
    //     const operatorJson = require('./operator.json')
    //     this.dataBox = operatorJson.operatorArray
    //   },
    async change(x) {
    this.current = x
    if (x==1) {
      this.getList()
      // let params = {
      //   menuId: "2038",
      //   id: "",
      // };
      // const res = await getContents(params);
      // for (let index = 0; index < res.length; index++) {
      //   // const zj = JSON.parse(res[0].content);
      //   res[index].content = JSON.parse(res[index].content);
      // }
      // // const zj = JSON.parse(res[0].content);
      // console.log(res, "res38");
      // this.dataBox = res;
      // this.dataBox= [
      //   {
      //     index: "01",
      //     title: "新能源发电解决方案",
      //     url: require("../img/新能源.png"),
      //     content:
      //       "公司提供分布式光伏电站、集中式光伏电站、风光互补电站、风力发电站等新能源发电项目可研、方案、设计、设备选型采购、项目管理及施工、后期运维全生命周期的新能源发电服务，包括电站项目开发、建设、交易、运维全过程交钥匙整体解决方案；标准化的电站评估交易体系；高效光伏组件；经验丰富的电站设计团队；先进的云计算监控管理平台；量身定制的运营方案，可显著提升电站综合发电效率，降低运维成本。",
      //   },
      //   {
      //     index: "02",
      //     title: "微电网解决方案",
      //     url: require("../img/微电网.png"),
      //     content: "公司依托自身在微电网领域十多年的技术基础及行业应用，针对产业园区、高校、工业企业、偏远地区等地区，提供微电网整体解决方案，包括方案的设计、设备采购、工程服务、运行维护的一站式EPC服务，实现微型、清洁、友好、自治的微电网。",
      //   },
      //   {
      //     index: "03",
      //     title: "储能解决方案",
      //     url: require("../img/储能.png"),
      //     content: "公司结合自身在新能源领域十多年的技术基础及行业应用，为客户提供储能整体解决方案，包括发电侧储能解决方案电网侧储能解决方案以及用户侧储能解决方案。为用户提供项目可研、方案、设计、设备选型采购、项目管理及施工后期运维全生命周期的系统服务。",
      //   },
      // ]
    } else if (x==2) {
      this.getList2()
      // let params = {
      //   menuId: "2039",
      //   id: "",
      // };
      // const res = await getContents(params);
      // for (let index = 0; index < res.length; index++) {
      //   // const zj = JSON.parse(res[0].content);
      //   res[index].content = JSON.parse(res[index].content);
      // }
      // // const zj = JSON.parse(res[0].content);
      // console.log(res, "res38");
      // this.dataBox = res;
      // this.dataBox= [
      //   {
      //     index: "01",
      //     title: "城市静态交通解决方案",
      //     url: require("../img/新能源.png"),
      //     content:
      //       "城市静态交通解决方案将行业经验与前沿技术完美融合，为城市静态交通治理提供“投、融、建、管、运”五位一体支撑，助力城市实现“停车上云、云上停车”，提供“治理一张图、运营一张网”的高质量监管、运营及服务，让城市快速进入一个更加智能、更加便携、更具有持续运营能力和服务能力的全场景智慧停车时代，有效缓解停车供需矛盾，进一步规范停车秩序，优化出行环境，构建停车生态，全面推动城市停车产业数字化转型，打造“以静制动、动静融合、良性循环、一体化运行”的智慧停车城市。",
      //   },
      //   {
      //     index: "02",
      //     title: "城市交管解决方案",
      //     url: require("../img/微电网.png"),
      //     content: "城市交管解决方案通过将先进的信息管理、数据通讯传输、电子传感、控制及计算机等技术有效的集成运用于整个地面交通管理系统，建立在大范围、全方位发挥作用的实时、准确、高效的综合交通管理控制系统，实现了交通管理的动态化、全局化、自动化、智能化。",
      //   },
      //   {
      //     index: "03",
      //     title: "智慧高速交通解决方案",
      //     url: require("../img/储能.png"),
      //     content: "智慧高速交通解决方案基于全数字网络，通过在高速公路道路、隧道、收费站、服务区等区域安装高清摄像机及LED诱导屏，对采集到的人、车、时间的数据进行分析、整理及挖掘，构建可视化的高速公路综合管理平台，并充分发挥管理者的管控、监测和协调能力，基于实时路况监测、违法抓拍管理、交通流量控制、交通信息发布等系统，实现高速交通智能化、科学化的管理以及安全高效、方便快捷的智慧出行服务。",
      //   },
      // ]
    }
}, 
    qiehuan(val) {
      let value = this.dataBox[0];
      this.dataBox[0] = this.dataBox[1];
      this.dataBox[1] = value;
      this.dataBox.splice(3)
    },
    qiehuan2(val) {
      let value = this.dataBox[0];
      this.dataBox[0] = this.dataBox[2];
      this.dataBox[2] = value;
      this.dataBox.splice(3)
    },
  },
  mounted() {
    this.getList()
    this.change(1)
  },
  // watch:{
  //       // 被侦听的变量count
  //       dataBox:{
  //           handler(newName, oldName) {
  //             this.dataBox=newName
  //               console.log('变化了');
  //           },
  //           immediate: true,
  //           deep: true
  //       }
        
  //   }
};
</script>

<style scoped>
.ckxz {
  margin-bottom: 1%;
  margin-top: 2%;
  margin-right: 44%;
  margin-left: 44%;
  width: 225px;
  height: 75px;
  background: #e5ebff;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-size: 23px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #0940fd;
}
.ckxz:hover {
  background: #0940fd;
  color: #ffffff;
}
.numCss {
  margin-top: 48%;
  margin-left: 5%;
  font-size: 27px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
.textInPic {
  position: absolute;
  top: 75%;
  right: 6%;
  left: 6%;
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.bigtitle {
  margin-top: 1%;
  font-size: 74px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
.boxCss {
  margin-top: 76px;
  margin-left: 12%;
  margin-right: 12%;
  /* height: 501px; */
  /* background-color: #8a3030; */
  display: flex;
  /* text-align: center; */
}
.neirCss {
}
.divCSs {
  width: 100%;
  /* background: #f6fafd; */
  position: relative;
  background: linear-gradient(#f6fafd, #f6fafd 78%, #ffffff 78%, #ffffff 100%);
  /* z-index: -2; */
  /* height: 850px; */
}
.beijing {
  position: absolute;
  white-space: nowrap;
  z-index: 2;
  left: 31%;
  top: 24%;
  opacity: 0.1;
  font-size: 135px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #777373;
  line-height: 0px;
}
.titleCss {
  margin-bottom: 2.2%;
  text-align: center;
  font-size: 45px;
  font-family: Alimama ShuHeiTi-Bold, Alimama ShuHeiTi;
  font-weight: bold;
  color: #000000;
}
.minCss {
  /* margin-left: 31%;
  margin-right: 31%; */
  margin-bottom: 24px;
  /* background-color: #c72e2e; */
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 9;
  padding: 1%;
  ;cursor: pointer;
}

.logoCss {
  text-align: center;
  padding-top: 119px;
  margin-bottom: 1%;
}
.buttonljgd1 {
  background: url("../img/能源1.png") no-repeat;
  background-size: 100% 100%;
  width: 354px;
  height: 97px;
}
.buttonljgd1:hover {
  box-shadow: 0px 0px 30px 1px rgba(9, 64, 253, 0.6);
  background: url("../img/能源2.png") no-repeat;
  border-radius: 15px;
  background-size: 100% 100%;
  width: 354px;
  height: 97px;
}
.buttonljgd3 {
  box-shadow: 0px 0px 30px 1px rgba(9, 64, 253, 0.6);
  background: url("../img/能源2.png") no-repeat;
  border-radius: 15px;
  background-size: 100% 100%;
  width: 354px;
  height: 97px;
}
.buttonljgd {
  margin-left: 2%;
  background: url("../img/城市1.png") no-repeat;
  background-size: 100% 100%;
  width: 354px;
  height: 97px;
}
.buttonljgd:hover {
  box-shadow: 0px 0px 30px 1px rgba(9, 64, 253, 0.6);
  background: url("../img/城市2.png") no-repeat;
  border-radius: 15px;
  background-size: 100% 100%;
  width: 354px;
  height: 97px;
}
.buttonljgd4 {
  box-shadow: 0px 0px 30px 1px rgba(9, 64, 253, 0.6);
  background: url("../img/城市2.png") no-repeat;
  border-radius: 15px;
  background-size: 100% 100%;
  width: 354px;
  height: 97px;
}
</style>