import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../public/Assets/font/fontt.css'

// 导入 vue-router 模块
import VueRouter from 'vue-router'
Vue.use(VueRouter)
 
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
// 调用回调方法 让浏览器不再报出这个错误
  return originalPush.call(this, location).catch(err => err)
}

Vue.config.productionTip = false
import VueQuillEditor from 'vue-quill-editor'  //引入富文本编译器
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor);

Vue.use(ElementUI)

//测试
// import{reqCategoryList} from '@/api';
// reqCategoryList();


new Vue({
  // 注册路由
  router,
  render: h => h(App),
  
}).$mount('#app')
