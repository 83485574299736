<template>
  <div class="divCSs">
    <div class="beijing">PRODUCT INTRODUCTION</div>
    <div class="logoCss">
      <img src="../img/产品类型.png" alt="" />
    </div>
    <div class="titleCss"><span>产品类型</span></div>
    <div class="minCss" >
      <div  class="leixing" :class="{'leixing2':index == current}" @click="qhIMG(index,item)" v-for="(item, index) in imgBox" :key="index">
        <img style="width: 40px; height: 40px;margin-top: 22px;" :src="item.url" alt="" />
        <div style="margin-top:9px">{{ item.title }}</div>
      </div>
    </div>

    <div class="block">
      <div class="prev" @click="arrowClick('prev')">
      </div>
    <el-carousel arrow="never" trigger="click" :interval="4000" style="width: 100%;border-radius: 15px;" height="540px" ref="cardShow">
      <el-carousel-item style="width: 100%;border-radius: 15px;" v-for="item in imagesbox" :key="item.id">
        <a >
          <img  ref="imgH" @click="go(item)"  style="width: 100%;height:540px;border-radius: 15px;cursor: pointer;" :src="item.url" alt="" >
        </a>
      </el-carousel-item>
    </el-carousel>
    <div class="next" @click="arrowClick('next')">
      </div>
  </div>

  </div>
</template>

<script>
import {getContents} from "@/api/index.js";
export default {
  data() {
    return {
      current: 0,
      imgBox: [
        {id:2012, title: "户用储能系列", url: require("../img/户用储能系列logo.png"), router: "/product" },
        {id:2013, title: "便携式储能系列", url: require("../img/便携式储能系列logo.png"), router: "/product" },
        {id:2014, title: "通信储能系列", url: require("../img/通信储能系列logo.png"), router: "/product" },
        {id:2015, title: "集中式储能系列", url: require("../img/电力储能系列logo.png"), router: "/product" },
        {id:2016, title: "工商业储能系列", url: require("../img/工商业储能系列logo.png"), router: "/product" },
        {id:2017, title: "电动汽车充电桩", url: require("../img/电动汽车充电桩logo.png"), router: "/product" },
      ],
      imagesbox: [
        { id: 0, url: require('../img/单相混合式逆变器.png'), router: "/ProductDetails" },
        { id: 1, url: require('../img/三相混合式逆变器.png'), router: "/sanxiangnibianqi" },
        { id: 2, url: require('../img/低压储能电池.png'), router: "/diyadianchizu"  },
        { id: 3, url: require('../img/高压储能电池.png'), router: "/gaoyadianchizu"  },
      ]
    };
  },
  methods: {
    //路由跳转事件
    async qhIMG(index,item) {
      console.log(index);
      console.log(item);
      this.current = index
      console.log(item,'idididi');
      let params={
        menuId:item==undefined?2012:item.id
      }
      const res = await getContents(params)
      console.log(res,'res');
      const zj=JSON.parse(res[0].content)
      console.log(zj,'ress');
      this.imagesbox= zj.imagesboxCP
      // if (index==0) {
      //   this.imagesbox=[
      //   { id: 0, url: require('../img/单相混合式逆变器.png'), router: "/ProductDetails" },
      //   { id: 1, url: require('../img/三相混合式逆变器.png'), router: "/sanxiangnibianqi" },
      //   { id: 2, url: require('../img/低压储能电池.png'), router: "/diyadianchizu"  },
      //   { id: 3, url: require('../img/高压储能电池.png'), router: "/gaoyadianchizu"  },
      // ]
      // }else if (index == 1) {
      //   this.imagesbox=[
      //   { id: 0, url: require('../img/600W户外电源.png'), router: "/600Whuwai" },
      //   { id: 1, url: require('../img/1200W户外电源.png'), router: "/1200Whuwai" },
      // ]
      // }else if (index == 2) {
      //   this.imagesbox=[
      //   { id: 0, url: require('../img/基站备电用磷酸铁锂电池.png'), router: "/jizhanbeiyong" },
      // ]
      // }else if (index == 3) {
      //   this.imagesbox=[
      //   { id: 0, url: require('../img/集装箱储能.png'), router: "/jizhuangxiang" },
      //   { id: 1, url: require('../img/液冷储能.png'), router: "/yelenchuneng" },
      //   { id: 2, url: require('../img/升压一体机.png'), router: "/shengyayitiji" },
      // ]
      // }else if (index == 4) {
      //   this.imagesbox=[
      //   { id: 0, url: require('../img/工商业储能-风冷.png'), router: "/fenglengchuneng" },
      //   { id: 1, url: require('../img/工商业储能-液冷.png'), router: "/prodyelengchunenguct" },
      // ]
      // }else if (index == 5) {
      //   this.imagesbox=[
      //   { id: 0, url: require('../img/壁挂直流充电桩.png'), router: "/jiaoliuchongdianzhuang" },
      //   { id: 1, url: require('../img/交流立柱.png'), router: "/jiaoliuchongdianzhuang" },
      //   { id: 2, url: require('../img/一体式直流充电桩.png'), router: "/yitizhiliu" },
      //   { id: 3, url: require('../img/200-360kW分体式直流充电桩.png'), router: "/fentizhiliu" },
      //   { id: 4, url: require('../img/480-600kW分体式直流充电桩.png'), router: "/fentizhiliu" },
      //   { id: 5, url: require('../img/壁挂直流充电桩.png'), router: "/guabizhiliu" },
      //   { id: 6, url: require('../img/高压液冷超充充电桩.png'), router: "/gaoyayeleng" },
      // ]
      // }
    },
    go(index) {
      this.$router.push(index.router);
    },
    arrowClick(val) {
      console.log(val);
      if(val == 'next') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },

  },
  mounted(){
    this.qhIMG()
  }
};
</script>

<style scoped>
.prev{
  margin-top: 14%;
    transform: translateY(-14%);
  background: url('../img/左.png') no-repeat;
  background-size: 100% 100%;
  height: 80px;
  width: 92px;
  cursor: pointer;
}
.prev:hover{
  background: url('../img/左蓝.png') no-repeat;
  background-size: 100% 100%;
  height: 80px;
  width: 92px;
}
.next{
  margin-top: 14%;
    transform: translateY(-14%);
  background: url('../img/右.png') no-repeat;
  background-size: 100% 100%;
  height: 80px;
  width: 92px;
  cursor: pointer;
}
.next:hover{
  margin-top: 14%;
    transform: translateY(-14%);
  background: url('../img/右蓝.png') no-repeat;
  background-size: 100% 100%;
  height: 80px;
  width: 92px;
}
.block{
  display: flex;
  text-align: center;
  margin-left: 6.5%;
  margin-right: 6.5%;
  position: relative;
    z-index: 9;
}
/* @media screen and (max-width: 720px) {
  .divCSs {
    display: none;
  }
} */
.divCSs {
  width: 100%;
  height: 100%;
  position: relative;
  background: linear-gradient(#FFFFFF ,#FFFFFF 23%,#F6FAFD 23%, #F6FAFD 100%);
  /* z-index: -2; */
  /* height: 850px; */
}
.beijing {
  position: absolute;
  white-space: nowrap;
  z-index: 2;
  left: 2.4%;
  TOP: 14.6%;
    opacity: 0.1;
  font-size: 135px;
font-family: Microsoft YaHei-Bold, Microsoft YaHei;
font-weight: bold;
color: #777373;
line-height: 0px;
}
.titleCss {
  margin-bottom: 2.2%;
  text-align: center;
  font-size: 45px;
  font-family: Alimama ShuHeiTi-Bold, Alimama ShuHeiTi;
  font-weight: bold;
  color: #000000;
}
.minCss {
  margin-left: 12%;
    margin-right: 12%;
    margin-bottom: 24px;
    display: flex;
    position: relative;
    z-index: 9;
}

.logoCss {
  text-align: center;
  margin-top: 119px;
  margin-bottom: 1%;
}
.leixing{
  text-align: center;
  width: 16.666%;
height: 120px;
background: #FFFFFF;
font-size: 20px;
font-family: Microsoft YaHei-Regular, Microsoft YaHei;
font-weight: 400;
color: #747070;
cursor: pointer;
}
.leixing:hover{
  background: #0940FD;
  font-size: 20px;
font-family: Microsoft YaHei-Bold, Microsoft YaHei;
font-weight: bold;
color: #FFFFFF;
}
.leixing2{
  text-align: center;
  width: 16.666%;
height: 120px;
background: #0940FD;
  font-size: 20px;
font-family: Microsoft YaHei-Bold, Microsoft YaHei;
font-weight: bold;
color: #FFFFFF;
}
</style>