import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/myHome/index.vue'
// import ChanPin from '../components/ChanPin.vue'
// 使用插件
Vue.use(VueRouter)
// 引入路由组件

// 配置路由
export default new VueRouter({
  routes: [
    // 重定向，在项目跑起来的时候，访问/，立马定向到首页
    {
      path: '/',
      // redirect: '/En-US'
      redirect: '/home'
    },
    // 中文路由
    // 中文路由
    // 中文路由
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: { show: true },
    },
    // 产品
    {
      path: '/product',
      name: 'product',
      meta: { show: true },
      component: () => import('../views/product/index.vue'),
    },
    // 储能系列产品
    {
      path: '/ProductDetails',
      name: 'ProductDetails',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/danxiangnibianqi.vue'),
    },
    {
      path: '/sanxiangnibianqi',
      name: 'sanxiangnibianqi',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/sanxiangnibianqi.vue'),
    },
    {
      path: '/diyadianchizu',
      name: 'diyadianchizu',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/diyadianchizu.vue'),
    },
    {
      path: '/gaoyadianchizu',
      name: 'gaoyadianchizu',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/gaoyadianchizu.vue'),
    },
    {
      path: '/600Whuwai',
      name: '600Whuwai',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/600Whuwai.vue'),
    },
    {
      path: '/1200Whuwai',
      name: '1200Whuwai',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/1200Whuwai.vue'),
    },
    {
      path: '/jizhanbeiyong',
      name: 'jizhanbeiyong',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/jizhanbeiyong.vue'),
    },
    {
      path: '/jizhuangxiang',
      name: 'jizhuangxiang',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/jizhuangxiang.vue'),
    },
    {
      path: '/yelenchuneng',
      name: 'yelenchuneng',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/yelenchuneng.vue'),
    },
    {
      path: '/fenglengchuneng',
      name: 'fenglengchuneng',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/fenglengchuneng.vue'),
    },
    {
      path: '/yelengchuneng',
      name: 'yelengchuneng',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/yelengchuneng.vue'),
    },
    {
      path: '/shengyayitiji',
      name: 'shengyayitiji',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/shengyayitiji.vue'),
    },
    {
      path: '/jiaoliuchongdianzhuang',
      name: 'jiaoliuchongdianzhuang',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/jiaoliuchongdianzhuang.vue'),
    },
    {
      path: '/yitizhiliu',
      name: 'yitizhiliu',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/yitizhiliu.vue'),
    },
    {
      path: '/fentizhiliu',
      name: 'fentizhiliu',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/fentizhiliu.vue'),
    },
    {
      path: '/guabizhiliu',
      name: 'guabizhiliu',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/guabizhiliu.vue'),
    },
    {
      path: '/gaoyayeleng',
      name: 'gaoyayeleng',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/gaoyayeleng.vue'),
    },
    {
      path: '/menjinkongzhiqi',
      name: 'menjinkongzhiqi',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/menjinkongzhiqi.vue'),
    },
    {
      path: '/menjindukaqi',
      name: 'menjindukaqi',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/menjindukaqi.vue'),
    },
    {
      path: '/diantikongzhiqi',
      name: 'diantikongzhiqi',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/diantikongzhiqi.vue'),
    },
    {
      path: '/renmianyitiji',
      name: 'renmianyitiji',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/renmianyitiji.vue'),
    },
    {
      path: '/gaoqingchepai',
      name: 'gaoqingchepai',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/gaoqingchepai.vue'),
    },
    {
      path: '/wurenzhishou',
      name: 'wurenzhishou',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/wurenzhishou.vue'),
    },
    {
      path: '/zhidongdaozha',
      name: 'zhidongdaozha',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/zhidongdaozha.vue'),
    },
    {
      path: '/cheweijiance',
      name: 'cheweijiance',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/cheweijiance.vue'),
    },
    {
      path: '/zhaomingxilie',
      name: 'zhaomingxilie',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/zhaomingxilie.vue'),
    },
    {
      path: '/loukongxilie',
      name: 'loukongxilie',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/loukongxilie.vue'),
    },
    {
      path: '/jichengguanli',
      name: 'jichengguanli',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/jichengguanli.vue'),
    },
    {
      path: '/nenghaoguanli',
      name: 'nenghaoguanli',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/nenghaoguanli.vue'),
    },
    {
      path: '/duogongnenggan',
      name: 'duogongnenggan',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/duogongnenggan.vue'),
    },
    {
      path: '/ganpingtai',
      name: 'ganpingtai',
      meta: { show: true,fanhui:true },
      component: () => import('../views/ProductDetails/ganpingtai.vue'),
    },





    {
      path: '/solution',
      name: 'solution',
      meta: { show: true },
      component: () => import('../views/solution/index.vue'),
    },
    {
      path: '/newEnergy',
      name: 'newEnergy',
      meta: { show: true,fanhui:true },
      component: () => import('../views/SolutionDetails/newEnergy.vue'),
    },
    {
      path: '/microgrid',
      name: 'microgrid',
      meta: { show: true,fanhui:true },
      component: () => import('../views/SolutionDetails/microgrid.vue'),
    },
    {
      path: '/StoredEnergy',
      name: 'StoredEnergy',
      meta: { show: true,fanhui:true },
      component: () => import('../views/SolutionDetails/StoredEnergy.vue'),
    },
    {
      path: '/chengshijt',
      name: 'chengshijt',
      meta: { show: true,fanhui:true },
      component: () => import('../views/SolutionDetails/chengshijt.vue'),
    },
    {
      path: '/jiaoguan',
      name: 'jiaoguan',
      meta: { show: true,fanhui:true },
      component: () => import('../views/SolutionDetails/jiaoguan.vue'),
    },
    {
      path: '/gaoshu',
      name: 'gaoshu',
      meta: { show: true,fanhui:true },
      component: () => import('../views/SolutionDetails/gaoshu.vue'),
    },
    {
      path: '/typicalCase',
      name: 'typicalCase',
      meta: { show: true },
      component: () => import('../views/typicalCase/index.vue'),
    },
    {
      path: '/typicalCaseDetails',
      name: 'typicalCaseDetails',
      meta: { show: true,fanhui:true },
      component: () => import('../views/typicalCaseDetails/index.vue'),
    },
    {
      path: '/News',
      name: 'News',
      meta: { show: true },
      component: () => import('../views/News/index.vue'),
    },
    // 新闻
    {
      path: '/index230609',
      name: 'index230609',
      meta: { show: true,fanhui:true },
      component: () => import('../views/NewsDetails/index230609.vue'),
    },
    {
      path: '/index230620',
      name: 'index230620',
      meta: { show: true,fanhui:true },
      component: () => import('../views/NewsDetails/index230620.vue'),
    },
    // 新闻
    {
      path: '/joinUs',
      name: 'joinUs',
      meta: { show: true },
      component: () => import('../views/joinUs/index.vue'),
    },
    {
      path: '/ContactUs',
      name: 'ContactUs',
      meta: { show: true },
      component: () => import('../views/ContactUs/index.vue'),
    },
    {
      path: '/ziliao',
      name: 'ziliao',
      meta: { show: true },
      component: () => import('../views/ziliao/index.vue'),
    },



    // 英文
    // 英文
    // 英文
    {
      path: '/En-US',
      name: 'En-US',
      meta: { show2: true },
      component: () => import('../views/En/myHome/index.vue')
      // component: () => import('../views/En/EnApp.vue')
    },
    // 产品
    {
      path: '/Enproduct',
      name: 'Enproduct',
      meta: { show2: true },
      component: () => import('../views/En/product/index.vue'),
    },
    // 储能系列产品
    {
      path: '/EnProductDetails',
      name: 'EnProductDetails',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/danxiangnibianqi.vue'),
    },
    {
      path: '/Ensanxiangnibianqi',
      name: 'Ensanxiangnibianqi',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/sanxiangnibianqi.vue'),
    },
    {
      path: '/Endiyadianchizu',
      name: 'Endiyadianchizu',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/diyadianchizu.vue'),
    },
    {
      path: '/Engaoyadianchizu',
      name: 'Engaoyadianchizu',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/gaoyadianchizu.vue'),
    },
    {
      path: '/En600Whuwai',
      name: 'En600Whuwai',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/600Whuwai.vue'),
    },
    {
      path: '/En1200Whuwai',
      name: 'En1200Whuwai',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/1200Whuwai.vue'),
    },
    {
      path: '/Enjizhanbeiyong',
      name: 'Enjizhanbeiyong',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/jizhanbeiyong.vue'),
    },
    {
      path: '/Enjizhuangxiang',
      name: 'Enjizhuangxiang',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/jizhuangxiang.vue'),
    },
    {
      path: '/Enfenglengchuneng',
      name: 'Enfenglengchuneng',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/fenglengchuneng.vue'),
    },
    {
      path: '/Enyelengchuneng',
      name: 'Enyelengchuneng',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/yelengchuneng.vue'),
    },
    {
      path: '/Enshengyayitiji',
      name: 'Enshengyayitiji',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/shengyayitiji.vue'),
    },
    {
      path: '/Enjiaoliuchongdianzhuang',
      name: 'Enjiaoliuchongdianzhuang',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/jiaoliuchongdianzhuang.vue'),
    },
    {
      path: '/Enyitizhiliu',
      name: 'Enyitizhiliu',
      meta: { show: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/yitizhiliu.vue'),
    },
    {
      path: '/Enfentizhiliu',
      name: 'Enfentizhiliu',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/fentizhiliu.vue'),
    },
    {
      path: '/Enguabizhiliu',
      name: 'Enguabizhiliu',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/guabizhiliu.vue'),
    },
    {
      path: '/Engaoyayeleng',
      name: 'Engaoyayeleng',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/gaoyayeleng.vue'),
    },
    {
      path: '/Enmenjinkongzhiqi',
      name: 'Enmenjinkongzhiqi',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/menjinkongzhiqi.vue'),
    },
    {
      path: '/Enmenjindukaqi',
      name: 'Enmenjindukaqi',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/menjindukaqi.vue'),
    },
    {
      path: '/Endiantikongzhiqi',
      name: 'Endiantikongzhiqi',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/diantikongzhiqi.vue'),
    },
    {
      path: '/Enrenmianyitiji',
      name: 'Enrenmianyitiji',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/renmianyitiji.vue'),
    },
    {
      path: '/Engaoqingchepai',
      name: 'Engaoqingchepai',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/gaoqingchepai.vue'),
    },
    {
      path: '/Enwurenzhishou',
      name: 'Enwurenzhishou',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/wurenzhishou.vue'),
    },
    {
      path: '/Enzhidongdaozha',
      name: 'Enzhidongdaozha',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/zhidongdaozha.vue'),
    },
    {
      path: '/Encheweijiance',
      name: 'Encheweijiance',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/cheweijiance.vue'),
    },
    {
      path: '/Enzhaomingxilie',
      name: 'Enzhaomingxilie',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/zhaomingxilie.vue'),
    },
    {
      path: '/Enloukongxilie',
      name: 'Enloukongxilie',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/loukongxilie.vue'),
    },
    {
      path: '/Enjichengguanli',
      name: 'Enjichengguanli',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/jichengguanli.vue'),
    },
    {
      path: '/Ennenghaoguanli',
      name: 'Ennenghaoguanli',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/nenghaoguanli.vue'),
    },
    {
      path: '/Enduogongnenggan',
      name: 'Enduogongnenggan',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/duogongnenggan.vue'),
    },
    {
      path: '/Enganpingtai',
      name: 'Enganpingtai',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/ProductDetails/ganpingtai.vue'),
    },





    {
      path: '/Ensolution',
      name: 'Ensolution',
      meta: { show2: true },
      component: () => import('../views/En/solution/index.vue'),
    },
    {
      path: '/EnnewEnergy',
      name: 'EnnewEnergy',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/SolutionDetails/newEnergy.vue'),
    },
    {
      path: '/Enmicrogrid',
      name: 'Enmicrogrid',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/SolutionDetails/microgrid.vue'),
    },
    {
      path: '/EnStoredEnergy',
      name: 'EnStoredEnergy',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/SolutionDetails/StoredEnergy.vue'),
    },
    {
      path: '/Enchengshijt',
      name: 'Enchengshijt',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/SolutionDetails/chengshijt.vue'),
    },
    {
      path: '/Enjiaoguan',
      name: 'Enjiaoguan',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/SolutionDetails/jiaoguan.vue'),
    },
    {
      path: '/Engaoshu',
      name: 'Engaoshu',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/SolutionDetails/gaoshu.vue'),
    },
    {
      path: '/EntypicalCase',
      name: 'EntypicalCase',
      meta: { show2: true },
      component: () => import('../views/En/typicalCase/index.vue'),
    },
    {
      path: '/EntypicalCaseDetails',
      name: 'EntypicalCaseDetails',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/typicalCaseDetails/index.vue'),
    },
    {
      path: '/EnNews',
      name: 'EnNews',
      meta: { show2: true },
      component: () => import('../views/En/News/index.vue'),
    },
    // 新闻
    {
      path: '/Enindex230609',
      name: 'Enindex230609',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/NewsDetails/index230609.vue'),
    },
    {
      path: '/Enindex230620',
      name: 'Enindex230620',
      meta: { show2: true,fanhui:true },
      component: () => import('../views/En/NewsDetails/index230620.vue'),
    },
    // 加入我们
    {
      path: '/EnjoinUs',
      name: 'EnjoinUs',
      meta: { show2: true },
      component: () => import('../views/En/joinUs/index.vue'),
    },
    // 联系我们
    {
      path: '/EnContactUs',
      name: 'EnContactUs',
      meta: { show2: true },
      component: () => import('../views/En/ContactUs/index.vue'),
    },
    // 技术支持
    {
      path: '/Enziliao',
      name: 'Enziliao',
      meta: { show2: true },
      component: () => import('../views/En/ziliao/index.vue'),
    },

    
  ]
})

