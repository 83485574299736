// 页尾
<template>
  <div class="bgcCss">
    <div class="bigCss">
      <div class="boxCss" v-for="(item,index) in dataList" :key="index">
        <div class="titleCss"><p style="text-align: center;">{{item.title}}</p></div>
        <ul  style="margin-top: 5%;text-align: center;">
          <li style="cursor: pointer;" v-for="(item2,index) in item.data" :key="index" @click="go(item2)">{{ item2.tiname }} </li>
        </ul>
      </div>
    </div>


    <div class="mingCss">
      <div class="dizhi">
        <div class="imgss"><img class="texttop" src="../img/地址.png" alt=""></img></div>
        <div class="textmin">Address</div>
        <div class="textbottom">武汉市东湖新技术开发区邮科院路88号</div>
      </div>
      <div class="youxiang">
        <div class="imgss"><img class="texttop" src="../img/邮箱.png" alt=""></img></div>
        <div class="textmin">Email</div>
        <div class="textbottom">AD@ffepower.com</div>
      </div>
      <div class="shichangbu">
        <div class="imgss"><img class="texttop" src="../img/电话1.png" alt=""></img></div>
        <div class="textmin">Marketing Department</div>
        <div class="textbottom">+86-27-87693118</div>
      </div>
      <div class="zhonghe">
        <div class="imgss"><img class="texttop" src="../img/电话1.png" alt=""></img></div>
        <div class="textmin">General management department</div>
        <div class="textbottom">+86-27-87693119</div>
      </div>
      <div class="erweima">
        <img class="textimg" src="../img/二维码.png" alt="">
        <div class="erbottom">Wechat public account</div>
      </div>
    </div>

    <div class="row1">
      <img src="../img/公司logo.png" />
      <span style="text-align: center;margin-top: 0.5%;">
        <a class="fz" href="https://beian.miit.gov.cn/"
          >Copyright ffepower 2022. All Rights Reserved. </a
        >
        <a class="fz" href="https://beian.miit.gov.cn/"
          > 鄂ICP备17022144号-1 </a
        >
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyFooter",
  methods: {
    go(index) {
      console.log(index);
      this.$router.push(index.router);
      console.log(index);
      if (index.tiname == "Taobao") {
        window.open(
          "https://shop283530163.taobao.com/shop/view_shop.htm?spm=a230r.1.14.4.5a7e1802WuuJuf&user_number_id=2214096846565",
          "_blank"
        );
      } else if (index.tiname == "Made-in-China net") {
        window.open("https://ffepower.en.made-in-china.com/", "_blank");
      }
    },
  },
  data() {
    return {
      dataList: [
        {
          title: "Product",
          data: [
            { tiname: "Stored Energy", router: "/Enproduct" },
            { tiname: "Charging Pile", router: "/Enproduct" },
            // { tiname: "Intelligentize", router: "/Enproduct" },
          ],
        },
        {
          title: "Solution",
          data: [
            { tiname: "Smart energy", router: "/Ensolution" },
            { tiname: "Smart city", router: "/Ensolution" },
          ],
        },
        {
          title: "Case",
          data: [
            { tiname: "Intelligent building", router: "/EntypicalCase" },
            { tiname: "Smart energy", router: "/EntypicalCase" },
            { tiname: "Intelligent transportation", router: "/EntypicalCase" },
          ],
        },
        {
          title: "News",
          data: [
            { tiname: "Company news", router: "/EnNews" },
            // { tiname: "行业动态" },
            // { tiname: "公司招标" },
          ],
        },
        {
          title: "E-commerce",
          data: [{ tiname: "Made-in-China net" }, { tiname: "Taobao" }],
        },
        {
          title: "Contact us",
          data: [
            { tiname: "Company profile", router: "/EnContactUs" },
            { tiname: "technical assistance", router: "/Enziliao" },
            // { tiname: "联系我们" },
            // { tiname: "荣誉资质" },
          ],
        },
        {
          title: "Join us",
          data: [
            { tiname: "Social recruitment", router: "/EnjoinUs" },
            // { tiname: "校园招聘", router: "/joinUs" },
            { tiname: "Agent recruitment", router: "/EnjoinUs" },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
/* 地址 */
.dizhi {
  margin-right: 3.3%;
  text-align: center;
  background: rgba(255, 255, 255, 0.6);
  width: 18.4%;
  border-radius: 12px 12px 12px 12px;
}
.dizhi:hover {
  margin-right: 3.3%;
  text-align: center;
  background: rgba(9, 64, 253, 1);
  width: 18.4%;
  border-radius: 12px 12px 12px 12px;
  .imgss img {
    content: url("../img/地址2.png");
  }
  .textmin {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
  }
  .textbottom {
    font-size: 20px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
  }
}
.erbottom {
  margin-top: 1%;
  margin-bottom: 11%;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.erweima {
  text-align: center;
  background: rgba(255, 255, 255, 1);
  width: 18.4%;
  border-radius: 12px 12px 12px 12px;
}
.youxiang {
  margin-right: 3.3%;
  text-align: center;
  background: rgba(255, 255, 255, 0.6);
  width: 18.4%;
  border-radius: 12px 12px 12px 12px;
}
.youxiang:hover {
  margin-right: 3.3%;
  text-align: center;
  background: rgba(9, 64, 253, 1);
  width: 18.4%;
  border-radius: 12px 12px 12px 12px;
  .imgss img {
    content: url("../img/邮箱2.png");
  }
  .textmin {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
  }
  .textbottom {
    font-size: 20px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
  }
}
.shichangbu {
  margin-right: 3.3%;
  text-align: center;
  background: rgba(255, 255, 255, 0.6);
  width: 18.4%;
  border-radius: 12px 12px 12px 12px;
}
.shichangbu:hover {
  margin-right: 3.3%;
  text-align: center;
  background: rgba(9, 64, 253, 1);
  width: 18.4%;
  border-radius: 12px 12px 12px 12px;
  .imgss img {
    content: url("../img/电话2.png");
  }
  .textmin {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
  }
  .textbottom {
    font-size: 20px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
  }
}
.zhonghe {
  margin-right: 3.3%;
  text-align: center;
  background: rgba(255, 255, 255, 0.6);
  width: 18.4%;
  border-radius: 12px 12px 12px 12px;
}
.zhonghe:hover {
  margin-right: 3.3%;
  text-align: center;
  background: rgba(9, 64, 253, 1);
  width: 18.4%;
  border-radius: 12px 12px 12px 12px;
  .imgss img {
    content: url("../img/电话2.png");
  }
  .textmin {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
  }
  .textbottom {
    font-size: 20px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
  }
}
.texttop {
  margin-top: 17%;
  margin-bottom: 10%;
}
.textimg {
  margin-top: 10%;
  /* margin-bottom: 10%; */
}
.textmin {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #585757;
}
.textbottom {
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 4%;
  margin-bottom: 7%;
  font-size: 20px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  overflow-wrap: anywhere;
}

/* 分割线 */
.boxCss {
  width: 14.2%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  border-left: 1px solid rgba(255, 255, 255, 0.05);
}
.boxCss li {
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 10%;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  /* opacity: 0.4; */
}
.boxCss li:hover {
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 10%;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  /* opacity: 0.4; */
}
.titleCss {
  /* text-align: center; */
  margin-bottom: 20%;
  /* border-left: 3px solid #0940fd; */
  font-size: 20px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 0px;
}
.titleCss::before {
  content: "";
  /* text-align: center; */
  border-left: 3px solid #0940fd;
  margin-left: -1%;
}
.liCss {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 0px;
}
.bigCss {
  display: flex;
  padding-top: 3%;
  margin-left: 15%;
  margin-right: 15%;
}
.mingCss {
  display: flex;
  padding-top: 3%;
  margin-left: 15%;
  margin-right: 15%;
}
.bgcCss {
  background: url("../img/底部背景.png") no-repeat;
  background-size: 100% 100%;
  height: 699px;
}
.row1 {
  margin-top: 3.2%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.row1 img {
  /* width: 13%; */
  width: 198px;
  height: 36px;
}
.fz {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 0px;
}

@media only screen and (max-width: 768px) {
  /* .row1 img {
    width: 76%;
  }
  .row img {
    width: 40%;
  } */
  .fz {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 0px;
  }
}
</style>
